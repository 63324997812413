

































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { ProblemUtilsProblem } from '../../utils/ProblemUtils';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import MenuWrapper from '../atoms/MenuWrapper.vue';
import ProblemChoiceMultiple from './ProblemChoiceMultiple.vue';
import ProblemChoiceSingle from './ProblemChoiceSingle.vue';
import { PropsProblemListItem, useProblemListItem } from './ProblemListItemComposable';

type Props = PropsProblemListItem;

export default defineComponent({
  name: 'BaseProblemListItem',
  components: { MenuWrapper, BaseMarkdown, ProblemChoiceMultiple, ProblemChoiceSingle },
  props: {
    value: { type: Boolean, default: false },
    problem: { type: Object as PropType<ProblemUtilsProblem>, required: true },
    iconPassed: { type: String, default: 'mdi-checkbox-marked-circle-outline' },
    iconNotPassed: { type: String, default: 'mdi-circle-small' },
    enableCorrect: { type: Boolean, default: false },
    enableSelect: { type: Boolean, default: false },
    initDisplayValue: { type: Boolean, default: false },
  },
  emits: ['change', 'click-anchor'],
  setup(props: Props, { emit }: SetupContext) {
    return useProblemListItem(props, emit);
  },
});
