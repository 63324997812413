import { computed } from '@vue/composition-api';

import { ChoiceProblemOption } from '@/base/domains';

import {
  BaseMarkdownClickAnchorPayload,
  BaseMarkdownMarkSelection,
} from '../atoms/BaseMarkdownComposable';

export type ProblemChoiceSingleOption = ChoiceProblemOption & { no: number };

export type ProblemChoiceSingleValue = number[];

export type PropsProblemChoiceSingle = {
  value: ProblemChoiceSingleValue;
  problemIndex: number;
  options: ProblemChoiceSingleOption[];
  enableMarking: boolean;
  disabled: boolean;
};

export type ProblemChoiceSingleMarkSelection = BaseMarkdownMarkSelection;

export type ProblemChoiceSingleClickAnchorPayload = BaseMarkdownClickAnchorPayload;

export function useProblemChoiceSingle(
  props: PropsProblemChoiceSingle,
  emit: (
    name: string,
    arg:
      | ProblemChoiceSingleValue
      | ProblemChoiceSingleMarkSelection
      | ProblemChoiceSingleClickAnchorPayload
  ) => void
) {
  const singleValue = computed(() => props.value[0]);
  function change(value: number) {
    if (props.disabled) return;
    emit('change', [value]);
  }

  function mark(payload: BaseMarkdownMarkSelection) {
    emit('mark', payload);
  }

  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  return { singleValue, change, mark, clickAnchor };
}
