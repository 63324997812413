import { onMounted, ref, watch } from '@vue/composition-api';

import { ChoiceProblemOption } from '@/base/domains';

import {
  BaseMarkdownClickAnchorPayload,
  BaseMarkdownMarkSelection,
} from '../atoms/BaseMarkdownComposable';

type ChoiceProblemOptionExt = ChoiceProblemOption & {
  checked: boolean;
};

export type ProblemChoiceMultipleOption = ChoiceProblemOption & { no: number };

export type ProblemChoiceMultipleValue = number[];

export type PropsProblemChoiceMultiple = {
  value: ProblemChoiceMultipleValue;
  problemIndex: number;
  options: ProblemChoiceMultipleOption[];
  enableMarking: boolean;
  disabled: boolean;
};

export type ProblemChoiceMultipleMarkSelection = BaseMarkdownMarkSelection;

export type ProblemChoiceMultipleClickAnchorPayload = BaseMarkdownClickAnchorPayload;

export function useProblemChoiceMultiple(
  props: PropsProblemChoiceMultiple,
  emit: (
    name: string,
    arg:
      | ProblemChoiceMultipleValue
      | ProblemChoiceMultipleMarkSelection
      | ProblemChoiceMultipleClickAnchorPayload
  ) => void
) {
  const inputs = ref<ChoiceProblemOptionExt[]>([]);

  function init() {
    inputs.value = props.options.map((o) => ({
      ...o,
      checked: props.value.includes(o.index),
    }));
  }
  onMounted(init);
  watch(() => props.problemIndex, init);
  watch(() => props.options, init);
  watch(() => props.value, init);

  function change(target: ChoiceProblemOptionExt) {
    if (props.disabled) return;
    Object.assign(target, { checked: !target.checked });
    const value = inputs.value.filter((input) => input.checked).map((input) => input.index);
    emit('change', value);
  }

  function mark(payload: BaseMarkdownMarkSelection) {
    emit('mark', payload);
  }

  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  return { inputs, change, mark, clickAnchor };
}
