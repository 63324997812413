import { computed, ComputedRef, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app/Messages';
import {
  ChoiceProblem,
  ChoiceProblemOption,
  ChoiceReviewProblemProblem,
  Exam,
  GroupExamContent,
  GroupExamProblem,
  ProblemHeader,
  ReviewProblemProblem,
  Workbook,
} from '@/base/domains';
import { Optional } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';
import { readonly, useLocalStorage } from '@/utils/VueUtils';

import { MarkerProblem } from './MarkerUtils';

function isBoolean(x?: unknown): x is boolean {
  if (x === undefined || x === null) return false;
  return typeof x === 'boolean';
}

function isNumber(x?: unknown): x is number {
  if (x === undefined || x === null) return false;
  return typeof x === 'number';
}

function isNumberArray(x?: unknown): x is number[] {
  if (x === undefined || x === null) return false;
  if (!Array.isArray(x)) return false;
  return x.every((i) => isNumber(i));
}

function isString(x?: unknown): x is string {
  if (x === undefined || x === null) return false;
  return typeof x === 'string';
}

function useTrainingStorage() {
  const trainingsStorage = useLocalStorage<Record<string, string | undefined>>(
    'base.problemUtils.trainings',
    {}
  );
  let trainingKey: Optional<string>;

  function deleteTraining() {
    if (!trainingKey) return;
    trainingsStorage.value = { ...trainingsStorage.value, [trainingKey]: undefined };
    trainingKey = undefined;
  }

  function existsTraining(key: string) {
    return !!trainingsStorage.value[key];
  }

  function stringify(v: ProblemUtilsResult[]) {
    return JSON.stringify(
      v.map((item) => ({
        i: item.index,
        c: item.choiceIndexes,
        s: item.scoredChoiceNos,
        p: item.passed,
        f: item.failures,
        m: item.marked,
        h: item.hash,
      }))
    );
  }

  function parse(x?: string): Optional<ProblemUtilsResult[]> {
    if (!x) return undefined;
    try {
      const arr = JSON.parse(x);
      if (!Array.isArray(arr)) return undefined;
      const results = arr
        .map((a) => {
          if (!a) return [];
          if (typeof a !== 'object') return [];
          const { i, c, s, p, f, m, h } = a;
          if (
            isNumber(i) &&
            isNumberArray(c) &&
            (s === undefined || isNumberArray(s)) &&
            (p === undefined || isBoolean(p)) &&
            (f === undefined || isNumber(f)) &&
            (m === undefined || isBoolean(m)) &&
            (h === undefined || isString(h))
          ) {
            return [
              {
                index: i,
                choiceIndexes: c,
                scoredChoiceNos: s,
                passed: p,
                failures: f,
                marked: m,
                hash: h,
              },
            ];
          }
          return [];
        })
        .flat();
      if (results.length !== arr.length) return undefined;
      return results;
    } catch (e) {
      return undefined;
    }
  }

  function getTraining() {
    if (!trainingKey) return undefined;
    return parse(trainingsStorage.value[trainingKey]);
  }

  function setTraining(v: ProblemUtilsResult[]) {
    if (!trainingKey) return;
    trainingsStorage.value = { ...trainingsStorage.value, [trainingKey]: stringify(v) };
  }

  function init(key?: string) {
    trainingKey = key;
    return getTraining();
  }

  return {
    existsTraining,
    deleteTraining,
    getTraining,
    setTraining,
    init,
  };
}

export type ProblemUtilsResult = {
  index: number;
  choiceIndexes: number[];
  scoredChoiceNos?: number[];
  passed?: boolean;
  failures?: number;
  marked?: boolean;
  hash?: string;
};

type ProblemOptionAndNo = ChoiceProblemOption & { no: number };

type ProblemAndNo = (
  | Omit<ChoiceProblem, 'options'>
  | Omit<ChoiceReviewProblemProblem, 'options'>
  | Omit<GroupExamProblem, 'options'>
) & {
  no: number;
  answerNos?: number[];
  options: ProblemOptionAndNo[];
  headerBody?: string;
  hasCommentaryMarker: boolean;
};

export type ProblemUtilsProblem = ProblemAndNo & Omit<ProblemUtilsResult, 'index'>;

export type ProblemUtilsNavigatorValue = number | 'results';

type NavigatorItem = {
  value: ProblemUtilsNavigatorValue;
  text: string;
  choose?: boolean;
  passed?: boolean;
  marked?: boolean;
};

const NAVIGATOR_ATTRIBUTE = {
  disabled: true,
  value: undefined as Optional<ProblemUtilsNavigatorValue>,
};
const NAVIGATOR = {
  items: [] as NavigatorItem[],
  prev: NAVIGATOR_ATTRIBUTE,
  next: NAVIGATOR_ATTRIBUTE,
  nextForce: NAVIGATOR_ATTRIBUTE,
};
export type ProblemUtilsNavigator = typeof NAVIGATOR;

export type ProblemUtilsConverter = (
  x: ProblemUtilsResult,
  saved: Optional<ProblemUtilsResult[]>,
  f: (v: number[]) => number[]
) => ProblemUtilsResult;

type ControlContent =
  | {
      type: 'workbook';
      workbook: Pick<Workbook, 'problems' | 'problemHeaders'>;
    }
  | {
      type: 'exam';
      body: Exam;
    }
  | {
      type: 'activeExam';
      body: GroupExamContent;
    }
  | {
      type: 'review';
      problems: ReviewProblemProblem[];
    };

export type PropsProblemController = {
  content: ComputedRef<Optional<ControlContent>>;
  markers?: ComputedRef<Optional<MarkerProblem[]>>;
  enableResults?: boolean | ((problems: ProblemUtilsProblem[], base?: ControlContent) => boolean);
};

export function useProblemController(props: PropsProblemController) {
  const msgs = useMessages({ prefix: 'base.utils.problemUtils' });
  const value = ref<ProblemUtilsNavigatorValue>();
  const results = ref<ProblemUtilsResult[]>([]);
  const storage = useTrainingStorage();

  function findResult(i: number) {
    return results.value?.find((item) => item.index === i);
  }

  function replaceResult(r: ProblemUtilsResult) {
    results.value = [...results.value.filter((item) => item.index !== r.index), r];
    storage.setTraining(results.value);
  }

  function formatOptionIndexToNo(indexes: number[], options: ProblemOptionAndNo[]) {
    return indexes
      .map((i) => {
        const option = options.find((o) => o.index === i);
        return option?.no ?? -1;
      })
      .sort((a, b) => a - b);
  }

  function formatProblems(
    problems: (ChoiceProblem | GroupExamProblem | ChoiceReviewProblemProblem)[],
    problemHeaders: ProblemHeader[],
    markers?: Optional<MarkerProblem[]>
  ): Optional<ProblemAndNo[]> {
    const getHeaderBody = (id?: string) => {
      const hBody = id ? problemHeaders.find((p) => p.id === id)?.body : undefined;
      return hBody;
    };
    const getOptionsAndAnswerNos = (p: ChoiceProblem | GroupExamProblem) => {
      const options = p.options.map((o, i) => ({ ...o, no: i + 1 }));
      const answerNos = 'answer' in p ? formatOptionIndexToNo(p.answer, options) : undefined;
      return { options, answerNos };
    };
    const getHasCommentaryMarker = (i: number) => {
      const ret =
        markers?.some(
          (item) => item.problemIndex === i && item.selection.position === 'commentary'
        ) ?? false;
      return ret;
    };
    return problems.map((p, i) => ({
      ...p,
      no: i + 1,
      headerBody: 'headerId' in p ? getHeaderBody(p.headerId) : undefined,
      hasCommentaryMarker: getHasCommentaryMarker(p.index),
      ...getOptionsAndAnswerNos(p),
    }));
  }

  function margeTrainingResult(p: ProblemAndNo, r?: ProblemUtilsResult) {
    return {
      ...p,
      choiceIndexes: r?.choiceIndexes ?? [],
      scoredChoiceNos: r?.scoredChoiceNos ?? [],
      passed: r?.passed ?? false,
      failures: r?.failures,
      marked: r?.marked,
    };
  }

  const problems = computed<Optional<ProblemUtilsProblem[]>>(() => {
    if (!props.content.value) return undefined;
    const { type } = props.content.value;
    if (type === 'activeExam' || type === 'exam') {
      const { body } = props.content.value;
      return formatProblems(body.problems, body.problemHeaders, props.markers?.value)?.map((p) =>
        margeTrainingResult(p, findResult(p.index))
      );
    }
    if (type === 'workbook') {
      const { workbook } = props.content.value;
      return formatProblems(workbook.problems, workbook.problemHeaders, props.markers?.value)?.map(
        (p) => margeTrainingResult(p, findResult(p.index))
      );
    }
    if (type === 'review') {
      return formatProblems(props.content.value.problems, [], [])?.map((p) =>
        margeTrainingResult(p, findResult(p.index))
      );
    }
    return undefined;
  });

  const navigator = computed<Optional<ProblemUtilsNavigator>>(() => {
    if (!problems.value) return NAVIGATOR;
    const list: NavigatorItem[] = problems.value.map((p) => ({
      value: p.index,
      text: msgs.of('problem', { no: p.no }).value,
      choose: p.choiceIndexes.length > 0,
      passed: p.passed,
      marked: p.marked,
    }));
    if (
      props.enableResults === true ||
      (props.enableResults ? props.enableResults(problems.value, props.content.value) : false) ===
        true
    )
      list.push({ value: 'results', text: msgs.of('results').value });
    const { value: av } = value;
    let ai = list.findIndex((item) => item.value === av);
    if (ai === -1) ai = 0;
    const p = list[ai - 1];
    const n = list[ai + 1];
    const nf = n ?? list.find((item) => item.passed === false);
    return {
      items: list,
      prev: { disabled: p === undefined, value: p?.value },
      next: { disabled: n === undefined, value: n?.value },
      nextForce: { disabled: nf === undefined, value: nf?.value },
    };
  });

  function first() {
    if (!problems.value || problems.value.length === 0) return;
    const [f] = problems.value;
    value.value = f.index;
  }

  function move(i?: ProblemUtilsNavigatorValue) {
    value.value = i;
  }

  function init(params?: {
    key?: string;
    enableFailureCounts?: boolean;
    enablePassed?: boolean;
    enableRaiseFlag?: boolean;
    converter?: ProblemUtilsConverter;
  }) {
    if (!props.content.value) return;
    const saved = storage.init(params?.key);

    const resultEmpty: Omit<ProblemUtilsResult, 'index' | 'hash'> = {
      choiceIndexes: [] as number[],
      scoredChoiceNos:
        params?.enableFailureCounts || params?.enablePassed ? ([] as number[]) : undefined,
      passed: params?.enableFailureCounts || params?.enablePassed ? false : undefined,
      failures: params?.enableFailureCounts ? 0 : undefined,
      marked: params?.enableRaiseFlag ? false : undefined,
    };

    results.value =
      problems.value?.map((p) => {
        const { index, hash, options } = p;
        const x = { ...resultEmpty, index, hash };
        if (params?.converter)
          return params?.converter(x, saved, (indexes: number[]) =>
            formatOptionIndexToNo(indexes, options)
          );
        return x;
      }) ?? [];
    storage.setTraining(results.value);

    first();
  }

  function exists(key: string) {
    return storage.existsTraining(key);
  }

  function remove() {
    storage.deleteTraining();
  }

  function clear(index: number) {
    const r = findResult(index);
    assertIsDefined(r, `result[${index}]`);
    replaceResult({ ...r, choiceIndexes: [], scoredChoiceNos: [], passed: false });
  }

  function choice(index: number, optionIndexes: number[]) {
    const r = findResult(index);
    assertIsDefined(r, `result[${index}]`);
    replaceResult({ ...r, choiceIndexes: [...optionIndexes] });
  }

  function score(index: number) {
    const p = problems.value?.find((item) => item.index === index);
    assertIsDefined(p, `problem[${index}]`);
    if (p.answerNos === undefined) return undefined;
    const r = findResult(index);
    assertIsDefined(r, `result[${index}]`);
    if (r.scoredChoiceNos === undefined || r.passed === undefined) return undefined;
    const scoredChoiceNos = formatOptionIndexToNo(r.choiceIndexes, p.options);
    const correct = p.answerNos.join() === scoredChoiceNos.join();
    const failures = r.failures === undefined ? undefined : r.failures + (correct ? 0 : 1);
    replaceResult({ ...r, scoredChoiceNos, passed: correct, failures });
    return correct;
  }

  function toggleFlag(index: number) {
    const r = findResult(index);
    assertIsDefined(r, `result[${index}]`);
    replaceResult({ ...r, marked: !(r.marked ?? false) });
  }

  return {
    value: readonly(value),
    problems,
    navigator,
    exists,
    first,
    move,
    init,
    remove,
    clear,
    choice,
    score,
    toggleFlag,
  };
}
