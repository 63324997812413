







































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '../atoms/BaseButton.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import {
  ProblemChoiceMultipleOption,
  ProblemChoiceMultipleValue,
  PropsProblemChoiceMultiple,
  useProblemChoiceMultiple,
} from './ProblemChoiceMultipleComposable';

type Props = PropsProblemChoiceMultiple;

export default defineComponent({
  name: 'BaseProblemChoiceMultiple',
  components: { BaseButton, BaseMarkdown },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Array as PropType<ProblemChoiceMultipleValue>, default: () => [] },
    problemIndex: { type: Number, required: true },
    options: { type: Array as PropType<ProblemChoiceMultipleOption[]>, default: () => [] },
    baseMarkerClassName: { type: String, default: 'problem-choice-multiple' },
    enableMarking: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: ['change', 'mark', 'click-anchor'],
  setup(props: Props, { emit }: SetupContext) {
    return useProblemChoiceMultiple(props, emit);
  },
});
