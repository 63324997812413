







































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '../atoms/BaseButton.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import {
  ProblemChoiceSingleOption,
  ProblemChoiceSingleValue,
  PropsProblemChoiceSingle,
  useProblemChoiceSingle,
} from './ProblemChoiceSingleComposable';

type Props = PropsProblemChoiceSingle;

export default defineComponent({
  name: 'BaseProblemChoiceSingle',
  components: { BaseButton, BaseMarkdown },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Array as PropType<ProblemChoiceSingleValue>, default: () => [] },
    problemIndex: { type: Number, required: true },
    options: { type: Array as PropType<ProblemChoiceSingleOption[]>, default: () => [] },
    baseMarkerClassName: { type: String, default: 'problem-choice-single' },
    enableMarking: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: ['change', 'mark', 'click-anchor'],
  setup(props: Props, { emit }: SetupContext) {
    return useProblemChoiceSingle(props, emit);
  },
});
