import { computed, onMounted, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app/Messages';
import { ProblemUtilsProblem } from '@/base/app/utils/ProblemUtils';

import { BaseMarkdownClickAnchorPayload } from '../atoms/BaseMarkdownComposable';
import { ProblemChoiceMultipleClickAnchorPayload } from './ProblemChoiceMultipleComposable';
import { ProblemChoiceSingleClickAnchorPayload } from './ProblemChoiceSingleComposable';

export type ProblemListItemChangePayload = {
  index: number;
  value: boolean;
};

export type ProblemListItemClickAnchorPayload =
  | BaseMarkdownClickAnchorPayload
  | ProblemChoiceMultipleClickAnchorPayload
  | ProblemChoiceSingleClickAnchorPayload;

export type PropsProblemListItem = {
  value: boolean;
  problem: ProblemUtilsProblem;
  initDisplayValue: boolean;
};

export function useProblemListItem(
  props: PropsProblemListItem,
  emit: (
    name: string,
    arg: ProblemListItemChangePayload | ProblemListItemClickAnchorPayload
  ) => void
) {
  const msgs = useMessages({ prefix: 'base.molecules.problemListItem' });

  const display = ref(false);

  const displayBody = ref(true);
  const displayCommentary = ref(true);

  const labelNo = computed(() => msgs.of('problem', { no: props.problem.no }).value);
  const labelFailures = computed(() => {
    if (!('failures' in props.problem) || !props.problem.failures) return undefined;
    return msgs.of('failures', { count: props.problem.failures }).value;
  });

  function init() {
    display.value = props.initDisplayValue;
  }
  onMounted(init);

  function toggle() {
    display.value = !display.value;
  }

  function change() {
    emit('change', { index: props.problem.index, value: !props.value });
  }

  function clickAnchor(
    payload:
      | BaseMarkdownClickAnchorPayload
      | ProblemChoiceMultipleClickAnchorPayload
      | ProblemChoiceSingleClickAnchorPayload
  ) {
    emit('click-anchor', payload);
  }

  return {
    display,
    displayBody,
    displayCommentary,
    labelNo,
    labelFailures,
    labelCorrect: msgs.of('correct'),
    labelIncorrect: msgs.of('incorrect'),
    labelChoices: msgs.of('choices'),
    labelAnswer: msgs.of('answer'),
    labelReviewLater: msgs.of('reviewLater'),
    labelQuestion: msgs.of('question'),
    labelCommentary: msgs.of('commentary'),
    labelNothing: msgs.of('nothing'),
    toggle,
    change,
    clickAnchor,
  };
}
